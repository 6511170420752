import { AxiosResponse } from "axios";
import { decamelizeKeys } from "humps";

import api from "~/utils/api/api";
import { UploadDoctorData, type AddDocumentResponse } from "~/utils/interfaces/Appointment";
import { Patient } from "~/utils/interfaces/AppointmentPatient";
import {
  IAssistedSetPatientsPayload,
  IAssistedSetPatientsResponse,
  ICreateMultipleV2Data,
  ICreateMultipleV2Response,
} from "~/utils/interfaces/Funnel";
import { User } from "~/utils/interfaces/User";

const appointmentsBaseUrl = "appointments";
const paymentsBaseUrl = "payments";
const dashboardAppointmentsBaseUrl = "dashboard/appointments";
const dashboardPatientsBaseUrl = "dashboard/patient";

class AppointmentService {
  fetchAppointment = async (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/${id}/`);
  };

  fetchDashboardAppointment = async (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardAppointmentsBaseUrl}/${id}/`);
  };

  searchAppointments = async (search: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardAppointmentsBaseUrl}/search/`, {
      params: { value: search },
    });
  };

  updateAppointment = async (id: string, data: any) => {
    return api.put(`${appointmentsBaseUrl}/${id}/`, decamelizeKeys(data));
  };

  reassignAppointment = async (id: string, data: any): Promise<AxiosResponse<any>> => {
    return api.put(`${appointmentsBaseUrl}/${id}/reassign/`, (data = data));
  };

  rescheduleAppointment = async (id: string, data: any): Promise<AxiosResponse<any>> => {
    return api.put(`${appointmentsBaseUrl}/${id}/reschedule/`, decamelizeKeys(data));
  };

  fetchUser = async (id?: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardPatientsBaseUrl}/${id}/`);
  };

  fetchUsers = async (search?: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardPatientsBaseUrl}/`, {
      params: { value: search },
    });
  };

  updateUser = async (id: string, data: Partial<User>): Promise<AxiosResponse<any>> => {
    delete data.country;
    return api.patch(`${dashboardPatientsBaseUrl}/${id}/`, (data = data));
  };

  fetchPatient = async (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardPatientsBaseUrl}/${id}/get_patient/`);
  };

  fetchPatients = async (search?: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardPatientsBaseUrl}/patients_list/`, {
      params: { value: search },
    });
  };

  updatePatient = async (id: string, data: Partial<User>): Promise<AxiosResponse<any>> => {
    delete data.country;
    return api.patch(`${dashboardPatientsBaseUrl}/${id}/update_patient/`, (data = data));
  };

  fetchPossibleNurses = async (id: string) => {
    return api.get(`${appointmentsBaseUrl}/${id}/possible_nurses/`);
  };

  fetchPossibleNursesV2 = async (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/${appointmentsBaseUrl}/${id}/available-nurses/`);
  };

  createOffliner = async (data: any): Promise<AxiosResponse<any>> => {
    return api.post(`${appointmentsBaseUrl}/offliner/create_offliner/`, (data = data));
  };

  manualConfirm = async (id: string, data: any = {}): Promise<AxiosResponse<any>> => {
    return api.post(`${appointmentsBaseUrl}/${id}/manual_confirm/`, data);
  };

  cancel = async (id: string): Promise<AxiosResponse<any>> => {
    return api.patch(`${appointmentsBaseUrl}/${id}/cancel/`);
  };

  manualCancel = async (id: string): Promise<AxiosResponse<any>> => {
    return api.patch(`${appointmentsBaseUrl}/${id}/manual_cancel/`);
  };

  createComment = async (id: string, data: any): Promise<AxiosResponse<any>> => {
    return api.post(`${appointmentsBaseUrl}/${id}/add_comment/`, (data = data));
  };

  deleteComment = async (id: string): Promise<AxiosResponse<any>> => {
    return api.delete(`${appointmentsBaseUrl}/delete_comment/`, {
      params: { comment_id: id },
    });
  };

  getPatients = async (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardAppointmentsBaseUrl}/${id}/patients/`);
  };

  searchPatient = async (document_type: string, document_number, strict = false): Promise<AxiosResponse<any>> => {
    return api.get(
      `/patients/search?document_type=${document_type}&document_number=${document_number}&strict=${strict}`,
    );
  };

  addPatient = async (appointment_id: string, patient_id: string): Promise<AxiosResponse<any>> => {
    return api.post(
      `${dashboardAppointmentsBaseUrl}/${appointment_id}/add_patient/`,
      decamelizeKeys({
        patient_id,
      }),
    );
  };

  createPatient = async (appointment_id: string, data: Patient): Promise<AxiosResponse<any>> => {
    return api.post(`${dashboardAppointmentsBaseUrl}/${appointment_id}/create_patient/`, decamelizeKeys(data));
  };

  changeAddress = async (id: string, data: any): Promise<AxiosResponse<any>> => {
    return api.patch(`${appointmentsBaseUrl}/${id}/change_address/`, data);
  };

  getFlatfileToken = async (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/flatfile_token_mx/`);
  };

  getFlatfileTokenMx = async (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/flatfile_token_mx/`);
  };

  downloadFlatfileTemplate = async (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/flatfile_template/`, {
      responseType: "blob",
    });
  };

  uploadRegistryImage = async (appointmentId: string, formData: FormData) => {
    return api.post<AddDocumentResponse>(`${appointmentsBaseUrl}/${appointmentId}/add_document/`, formData);
  };

  createMultipleV2 = async (data: ICreateMultipleV2Data): Promise<AxiosResponse<ICreateMultipleV2Response>> => {
    return api.post<ICreateMultipleV2Response>(`${appointmentsBaseUrl}/create_multiple_v2/`, data);
  };

  assistedSetPatients = async (
    id: string,
    data: IAssistedSetPatientsPayload,
  ): Promise<AxiosResponse<IAssistedSetPatientsResponse>> => {
    return api.post<IAssistedSetPatientsResponse>(`${appointmentsBaseUrl}/${id}/assisted_set_patients/`, data);
  };

  setPatientsAndCart = async (
    id: string,
    data: IAssistedSetPatientsPayload,
  ): Promise<AxiosResponse<IAssistedSetPatientsResponse>> => {
    return api.post<IAssistedSetPatientsResponse>(`v2/appointments/${id}/assisted_set_patients/`, data);
  };

  fetchFingerprintAppointments = async (
    beginDate: string,
    endDate: string,
    status: string,
    insurance: string,
  ): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/fingerprint-required/`, {
      params: { begin_date: beginDate, end_date: endDate, status, insurance },
    });
  };

  totalpackValuation = async (id: string): Promise<AxiosResponse<any>> => {
    return api.post(`v2/totalpack/${id}/valuation/`, {
      from_betty: true,
    });
  };

  totalpackPaymentConfirm = async (id: string): Promise<AxiosResponse<any>> => {
    return api.post(`v2/totalpack/${id}/commit/`);
  };

  uploadMedicalOrderImageToCreatedAppointment = async (appointmentId: string, formData: FormData) => {
    formData.append("triggered_from", "betty");
    return api.post(`${appointmentsBaseUrl}/${appointmentId}/upload_image_medical_order/`, formData);
  };

  getDoctorData = async (appointmentId: string) => {
    return api.get(`${appointmentsBaseUrl}/${appointmentId}/get_doctor_data/`);
  };

  uploadMedicalOrderDoctorData = async (appointmentId: string, data: UploadDoctorData) => {
    return api.post(`${appointmentsBaseUrl}/${appointmentId}/upload_doctor_data/`, decamelizeKeys(data));
  };

  sendToTotalpackDashboard = async (id: string): Promise<AxiosResponse<any>> => {
    return api.post(`${appointmentsBaseUrl}/${id}/totalpack-dashboard/`);
  };

  removeFromTotalpackDashboard = async (id: string): Promise<AxiosResponse<any>> => {
    return api.delete(`${appointmentsBaseUrl}/${id}/totalpack-dashboard/`);
  };

  registerPayment = async (appointmentId: string, data: FormData): Promise<AxiosResponse<any>> => {
    return api.post(`${paymentsBaseUrl}/appointments/${appointmentId}/register_payment/`, data);
  };

  deletePayment = async (appointmentId: string, paymentId: string): Promise<AxiosResponse<any>> => {
    return api.delete(`${paymentsBaseUrl}/appointments/${appointmentId}/delete_payment/`, {
      params: { id: paymentId },
    });
  };
  fitDetails = async (appointmentId: string): Promise<AxiosResponse<any>> => {
    return api.post(`${paymentsBaseUrl}/appointments/${appointmentId}/fit_details_after_deletion/`);
  };

  getOperativeData = async (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/${id}/operative_data/`);
  };

  updateOperativeData = async (id: string, data: any): Promise<AxiosResponse<any>> => {
    return api.put(`${appointmentsBaseUrl}/${id}/operative_data/`, data);
  };

  fetchSalesSources = async (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/sales_sources/`);
  };

  releaseBlock = async (id: string): Promise<AxiosResponse<any>> => {
    return api.post(`${dashboardAppointmentsBaseUrl}/${id}/release_block/`);
  };
}

const appointmentService = new AppointmentService();

export default appointmentService;
