import React, { ChangeEvent, Dispatch, SetStateAction } from "react";

import TextField from "@material-ui/core/TextField";

import { OperativeAppointmentData } from "~/utils/interfaces/Appointment";

interface CompanyOperativeFormProps {
  operativeData?: OperativeAppointmentData;
  setOperativeData: Dispatch<SetStateAction<OperativeAppointmentData | undefined>>;
}

const CompanyOperativeForm = ({ operativeData, setOperativeData }: CompanyOperativeFormProps): JSX.Element => {
  const formChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!operativeData) {
      return;
    }
    const newValue = event.target.value;
    const attribute = event.target.id;
    setOperativeData({
      ...operativeData,
      company: { ...operativeData.company, [attribute]: newValue },
    });
  };

  return (
    <React.Fragment>
      {operativeData && (
        <React.Fragment>
          <h4>Datos empresa</h4>
          <div className="flex flex-row flex-nowrap items-center">
            <TextField
              id="contact_email"
              onChange={formChange}
              value={operativeData.company.contact_email}
              helperText="Email de contacto"
              fullWidth
              className="m-2"
            />
            <TextField
              id="contact_name"
              onChange={formChange}
              value={operativeData.company.contact_name}
              helperText="Nombre de contacto"
              fullWidth
              className="m-2"
            />
          </div>
          <div className="flex flex-row flex-nowrap items-center">
            <TextField
              id="contact_phone"
              onChange={formChange}
              value={operativeData.company.contact_phone}
              helperText="Teléfono de contacto"
              fullWidth
              className="m-2"
            />
            <TextField
              id="document_id"
              onChange={formChange}
              value={operativeData.company.document_id}
              helperText="Número de documento (empresa)"
              fullWidth
              className="m-2"
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CompanyOperativeForm;
